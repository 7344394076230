<template>
  <nav-bar @lang="getLang" />
  <div class="container">
    <div class="content">
      <router-view :lang=lang />
    </div>
  </div>
  <site-footer :lang="lang" />
</template>
<script>
  import NavBar from "@/components/NavBar.vue";
  import SiteFooter from "@/components/SiteFooter.vue";
  export default {
    components: {
      NavBar,
      SiteFooter,
    },
    data() {
      return {
        lang: String,
      }
    },
    methods: {
      getLang: function (val) {
        this.lang = val;
      }
    },
    created() {}
  }
</script>