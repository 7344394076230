<template>
  <div class="footer">
    <div v-html="d('en').desc.join(' ')" :class=lang class="disclaimers">
    </div>
    <br />
    <div>&copy;2022 - TC Legal Services. All Right Reserved. <p>Powered by <a href="https://www.dawnsoft.ca"
          target="_blank">Dawnsoft Technologies Canada</a></p>
    </div>
  </div>
</template>
<script>
  import Disclaimers from "@/disclaimers.json";
  export default {
    props: {
      lang: String
    },
    data() {
      return {
        Disclaimers: Object
      }
    },
    methods: {
      d: function (x) {
        return x === this.lang ? Disclaimers.disclaimer.find(d => d.lang === "en") : Disclaimers.disclaimer.find(d =>
          d.lang === "cn")
      }
    },
  }
</script>