<template>
  <nav class="navbar">
    <!-- LOGO -->
    <div class="logo">
      <router-link to="/" class="logo" title="TC Legal Services">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="420 0 200 200">
          <g stroke="#FFF" stroke-width="5" stroke-linecap="round" fill="none">
            <circle cx="100" cy="100" r="98" />
            <path d="M35 50, c45 10, 45 0, 65 -8, c20 8 ,20 18, 65 8" />
            <path d="M100 25, V150" stroke-width="10" />
            <path d="M35 50, l-20 75, l40 0, z" stroke-width="3" />
            <path d="M165 50, l-20 75, l40 0, z" stroke-width="3" />
            <path d="M14 125, l42 0, l-6 5, l-30 0, z" stroke-width="3" stroke-linejoin="round" fill="#FFF" />
            <path d="M144 125, l42 0, l-6 5, l-30 0, z" stroke-width="3" stroke-linejoin="round" fill="#FFF" />
            <path d="M100 155, l-40 15, l80 0, z" stroke-width="2" fill="#FFF" />
            <path d="M60 170, l80 0, l0 3, l-80 0, z" stroke-width="2" fill="#FFF" />
          </g>
          <g fill="#FFF">
            <circle cx="35" cy="50" r="8" />
            <circle cx="165" cy="50" r="8" />
            <rect x="92" y="27" ry="2" width="16" height="8" />
            <rect x="92" y="38" ry="2" width="16" height="16" />
            <rect x="90" y="130" ry="3" width="20" height="30" />
          </g>
          <g font-size="36" font-family="Verdana" font-weight="bold" fill="#FFF" stroke="none">
            <text x="75" y="108" text-anchor="middle">T</text>
            <text x="125" y="108" text-anchor="middle">C</text>
            <text x="250" y="150" font-size="90" font-weight="normal">TC Legal Services</text>
          </g>
        </svg>
      </router-link>
    </div>
    <!-- NAVIGATION MENU -->
    <ul class="nav-links" :class="lang">
      <!-- USING CHECKBOX HACK -->
      <input type="checkbox" id="checkbox_toggle" />
      <label for="checkbox_toggle" class="hamburger">&#9776;</label>
      <!-- NAVIGATION MENUS -->
      <div class="menu">
        <li>
          <router-link :to="{name: 'home', params: {lang: lang}}">{{ menu.home }}</router-link>
        </li>
        <li>
          <router-link :to="{name: 'about', params: {lang: lang}}">{{ menu.about }}</router-link>
        </li>
        <li class="services">
          <a href="#" :class="servicesClass">{{ menu.services }}</a>
          <!-- DROPDOWN MENU -->
          <ul class="dropdown">
            <li v-for="service in Services" :key="service.id">
              <router-link :to="{name: 'services', params:{lang: lang, slug: service.slug}}">{{ service.name }}
              </router-link>
            </li>
          </ul>
        </li>
        <li>
          <router-link :to="{name: 'contact',params: {lang: lang}}">{{ menu.contact }}</router-link>
        </li>
        <li>
          <a href="/" @click="changeLang($event)" v-if="lang=='en'" class="cn">{{ menu.yuyan }}</a>
          <a href="/" @click="changeLang($event)" v-else>{{ menu.yuyan }}</a>
        </li>
      </div>
    </ul>
  </nav>
</template>
<script>
  import Services from "@/services.json";
  const CN = {
    home: "首 页",
    about: "关于我们",
    services: "服务范围",
    contact: "联系方式",
    yuyan: "English",
  };
  const EN = {
    home: "Home",
    about: "About",
    services: "Services",
    contact: "Contact",
    yuyan: "中 文",
  };
  export default {
    data() {
      return {
        lang: String,
        menu: {},
        Services: Object,
        servicesClass: '',
      }
    },
    watch: {
      $route() {
        this.servicesClass = location.href.toLowerCase().indexOf('/services/') != -1 ? 'router-link-exact-active' : '';
      }
    },
    methods: {
      changeLang: function (e) {
        e.preventDefault()
        this.lang = this.lang == 'en' ? 'cn' : 'en';
        this.$cookies.set("lang", this.lang, "7d")
        let url = window.location.pathname;
        url = this.lang == "en" ? url.replace("/cn/", "/en/") : url.replace("/en/", "/cn/")
        this.$router.push(url);
        this.loadMenu(this.lang);
        this.Services = this.lang == "en" ? Services.english : Services.chinese;
        this.$emit('lang', this.lang);
      },
      loadMenu: function (x) {
        this.menu = x == "cn" ? CN : EN;
      }
    },
    mounted() {
      this.lang = "en";
      if (this.$cookies.get('lang') != null) {
        this.lang = this.$cookies.get('lang');
      }
      this.loadMenu(this.lang);
      this.Services = this.lang == "en" ? Services.english : Services.chinese;
      this.$emit('lang', this.lang);
    }
  }
</script>