import {
  createRouter,
  createWebHistory
} from 'vue-router'

//import HomeView from '@/views/HomeView.vue'

const routes = [{
    path: '/',
    name: 'index',
    component: () => import('@/views/Home'),
  },
  {
    path: '/:lang/',
    name: 'home',
    component: () => import('@/views/HomeView'),
    meta: {
      title: 'TC Legal Services',
      metaTags: [{
          name: 'description',
          content: 'The home page of TC Legal Services'
        },
        {
          property: 'og:description',
          content: 'The home page of TC Legal Services.'
        },
        {
          name: 'keywords',
          content: 'Markham paralegal, Markham legal services, GTA paralegal services, paralegal, paralegals, ' +
            'small claim, small claim court, landlord and tenant, provincial offences, GTA legal services, ' +
            'criminal charge defences, immigration hearings and appeals, notary public, GTA paralegal services, ' +
            'notary public and commissioner services, TC Legal Services'
        }
      ]
    }
  },
  {
    path: '/:lang/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '@/views/AboutView.vue'),
    meta: {
      title: 'TC Legal Services - About us',
      metaTags: [{
          name: 'description',
          content: 'The about us page of TC Legal Services'
        },
        {
          property: 'og:description',
          content: 'The about page of TC Legal Services.'
        },
        {
          name: 'keywords',
          content: 'Markham paralegal, Markham legal services, GTA paralegal services, paralegal, paralegals,' +
            'small claim, small claim court, landlord and tenant, provincial offences, GTA legal services ' +
            'criminal charge defences, immigration hearings and appeals, natary public, GTA paralegal services' +
            'natary public and commissioner services, TC Legal Services'
        }
      ]
    }
  },
  {
    path: '/:lang/services/:slug',
    name: 'services',
    component: () => import('@/views/ServicesView'),
    meta: {
      title: 'TC Legal Services - Services',
      metaTags: [{
          name: 'description',
          content: 'The services page of TC Legal Services'
        },
        {
          property: 'og:description',
          content: 'The services page of TC Legal Services.'
        },
        {
          name: 'keywords',
          content: 'Markham paralegal, Markham legal services, GTA paralegal services, paralegal, paralegals,' +
            'small claim, small claim court, landlord and tenant, provincial offences, GTA legal services ' +
            'criminal charge defences, immigration hearings and appeals, natary public, GTA paralegal services' +
            'natary public and commissioner services, TC Legal Services'
        }
      ]
    }
  },
  {
    path: '/:lang/contact',
    name: 'contact',
    component: () => import('@/views/ContactView'),
    meta: {
      title: 'TC Legal Services - Contact us',
      metaTags: [{
          name: 'description',
          content: 'The contact us page of TC Legal Services'
        },
        {
          property: 'og:description',
          content: 'The contact us page of TC Legal Services.'
        },
        {
          name: 'keywords',
          content: 'Markham paralegal, Markham legal services, GTA paralegal services, paralegal, paralegals,' +
            'small claim, small claim court, landlord and tenant, provincial offences, GTA legal services ' +
            'criminal charge defences, immigration hearings and appeals, natary public, GTA paralegal services' +
            'natary public and commissioner services, TC Legal Services'
        }
      ]
    }
  },
  {
    path: '/:catchAll(.*)*',
    name: "page-not-found",
    component: () => import('@/views/PageNotFound'),
    meta: {
      title: 'TC Legal Services - Page not found',
      metaTags: [{
          name: 'description',
          content: 'The home page of TC Legal Services'
        },
        {
          property: 'og:description',
          content: 'The home page of TC Legal Services.'
        },
        {
          name: 'keywords',
          content: 'Markham paralegal, Markham legal services, GTA paralegal services, paralegal, paralegals,' +
            'small claim, small claim court, landlord and tenant, provincial offences, GTA legal services ' +
            'criminal charge defences, immigration hearings and appeals, natary public, GTA paralegal services' +
            'natary public and commissioner services, TC Legal Services'
        }
      ]
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router